<template>
    <prime-dialog
        v-model:visible="errorPopupVisible"
        :modal="true"
        header="Heads Up!"
    >
        <div class="text-2xl">
            Whoops! Something went wrong. Please try again and/or reload the
            page to try again.
        </div>

        <div
            v-if="isDev"
            class="mt-8 border border-gray-300 bg-gray-100 p-4"
        >
            ERROR: {{ lastError }}
        </div>
    </prime-dialog>

    <nuxt-layout>
        <nuxt-loading-indicator />
        <NuxtPage />
    </nuxt-layout>
</template>

<script setup lang="ts">
import NuxtLayout from '#app/components/nuxt-layout'
import NuxtLoadingIndicator from '#app/components/nuxt-loading-indicator'
import PrimeDialog from 'primevue/dialog'
import { onErrorCaptured, ref } from 'vue'
import { useLoadingIndicator } from '#app/composables/loading-indicator.js'
import { createRecaptcha } from '~/composables/create-recaptcha'

// https://github.com/nuxt/nuxt/issues/25840
const { finish: finishNuxtLoading } = useLoadingIndicator()
finishNuxtLoading()
if (process.client) {
    finishNuxtLoading()
}

createRecaptcha()

const isDev = import.meta.env.DEV
const errorPopupVisible = ref(false)
const lastError = ref('')
// const ignoredErrors = [
//     'Failed to fetch',
//     'Load failed',
//     'TypeError: Load failed',
//     'NetworkError when attempting to fetch resource.'
// ]
// onErrorCaptured(error => {
//     if (!ignoredErrors.includes(error.message)) {
//         errorPopupVisible.value = true
//         lastError.value = error.message
//     }
// })

// Probably don't want this because it will show too many errors, leaving commented out for now
// function handleWindowError(error: ErrorEvent) {
//     errorPopupVisible.value = true
//     lastError.value = error.message
// }
// onMounted(() => {
//     window.addEventListener('error', handleWindowError)
// })
// onUnmounted(() => {
//     window.removeEventListener('error', handleWindowError)
// })
</script>

<style lang="postcss">
:root {
    --orange-500: #f97316;
    --surface-ground: #eff3f8;

    /*
    --color-primary-50: 167 213 225;
    --color-primary-100: 167 213 225;
    --color-primary-200: 167 213 225;
    --color-primary-300: 167 213 225;
    --color-primary-400: 107 185 206;
    --color-primary-500: 107 185 206;
    --color-primary-600: 107 185 206;
    --color-primary-700: 95 165 184;
    --color-primary-800: 95 165 184;
    --color-primary-900: 95 165 184;
    --color-primary-950: 95 165 184;
     */

    /*
    --fc-small-font-size: 0.85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: hsla(0, 0%, 82%, 0.3);
    --fc-neutral-text-color: grey;
    --fc-border-color: #ddd;
     */

    --fc-neutral-bg-color: #f1f5f9; /* slate-100 */
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #64748b;
    --fc-button-border-color: #64748b;
    --fc-button-hover-bg-color: #334155;
    --fc-button-hover-border-color: #334155;
    --fc-button-active-bg-color: #0f172a;
    --fc-button-active-border-color: #0f172a;

    /*
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: hsla(0, 0%, 84%, 0.3);
    --fc-bg-event-color: #8fdf82;
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-now-indicator-color: red;
     */
}
/*
SFRC:
    --color-primary-50: 147 209 235;
    --color-primary-100: 147 209 235;
    --color-primary-200: 147 209 235;
    --color-primary-300: 147 209 235;
    --color-primary-400: 110 193 228;
    --color-primary-500: 110 193 228;
    --color-primary-600: 110 193 228;
    --color-primary-700: 40 163 215;
    --color-primary-800: 40 163 215;
    --color-primary-900: 32 130 172;
    --color-primary-950: 32 130 172;
EW:
#A7D5E1 => rgb(167, 213, 225)
#6BB9CE => rgb(107, 185, 206)
#5FA5B8 => rgb(95, 165, 184)
    --color-primary-50: 167 213 225;
    --color-primary-100: 167 213 225;
    --color-primary-200: 167 213 225;
    --color-primary-300: 167 213 225;
    --color-primary-400: 107 185 206;
    --color-primary-500: 107 185 206;
    --color-primary-600: 107 185 206;
    --color-primary-700: 95 165 184;
    --color-primary-800: 95 165 184;
    --color-primary-900: 95 165 184;
    --color-primary-950: 95 165 184;
*/

html,
body,
#__nuxt {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: 'Poppins', Helvetica, Helvetica Neue, Arial, Lucida Grande,
        sans-serif;
    font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
    font-variation-settings: normal;
}

body {
    font-weight: 400;
    padding: 0;
    margin: 0;
    min-height: 100%;
    color: var(--text-color);
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply dark:bg-slate-800 text-slate-900 bg-white dark:text-white;
}

.ContentReset p {
    margin-block-end: 1em;
    margin-block-start: 1em;
}

.ContentReset a {
    color: rgb(var(--color-primary-600));
    @apply underline;
}

.ContentReset ul {
    list-style-type: disc;
    margin-block-end: 1em;
    margin-block-start: 1em;
    padding-inline-start: 40px;
}
</style>

import type { PlanType } from '~/resources/graphql/graphql'
import { useLayoutMode } from '~/composables/use-layout-mode'

export function useRouteLink() {
    const { preserveModeLink } = useLayoutMode()

    return {
        orgHomeLink() {
            return preserveModeLink('/')
        },

        orgSignInLink(redirect: string) {
            return preserveModeLink(
                `/sign-in?redirect=${encodeURIComponent(redirect)}`
            )
        },

        emptyLink() {
            return preserveModeLink(unsafe_emptyLink())
        },

        facilityHomeLink(facilitySlug: string) {
            return preserveModeLink(`/${facilitySlug}`)
        },

        facilitySignInLink(facilitySlug: string) {
            return preserveModeLink(`/${facilitySlug}/sign-in`)
        },

        facilitySignInRedirectLink(facilitySlug: string, redirect: string) {
            return preserveModeLink(
                `/${facilitySlug}/sign-in?redirect=${encodeURIComponent(
                    redirect
                )}`
            )
        },

        facilityCartLink(facilitySlug: string) {
            return preserveModeLink(unsafe_facilityCartLink(facilitySlug))
        },

        facilityMembershipLink(facilitySlug: string, membershipId: string) {
            return preserveModeLink(
                `/${facilitySlug}/memberships/${membershipId}`
            )
        },

        facilityProgramLink(facilitySlug: string, programId: string) {
            return preserveModeLink(`/${facilitySlug}/programs/${programId}`)
        },

        facilityCheckoutLink(facilitySlug: string, checkoutId: string) {
            return preserveModeLink(`/${facilitySlug}/checkout/${checkoutId}`)
        },

        facilityEditContractLink(
            facilitySlug: string,
            planType: PlanType,
            contractId: string
        ) {
            if (planType === 'AGREEMENT') {
                return preserveModeLink(
                    `/${facilitySlug}/agreement/${contractId}`
                )
            }

            if (planType === 'MEMBERSHIP') {
                return preserveModeLink(
                    `/${facilitySlug}/membership/${contractId}`
                )
            }

            if (planType === 'PASS') {
                return preserveModeLink(`/${facilitySlug}/pass/${contractId}`)
            }

            if (planType === 'PROGRAM') {
                return preserveModeLink(
                    `/${facilitySlug}/booking/${contractId}`
                )
            }

            if (planType === 'GIFTCARD') {
                return preserveModeLink(
                    `/${facilitySlug}/gift-card/${contractId}`
                )
            }

            throw new Error()
        },

        facilityCreateContractLink(
            storefrontPrefix: string,
            planType: PlanType,
            slug: string
        ) {
            if (planType === 'AGREEMENT') {
                return preserveModeLink(
                    `${storefrontPrefix}/agreements/${slug}`
                )
            }

            if (planType === 'MEMBERSHIP') {
                return preserveModeLink(
                    `${storefrontPrefix}/memberships/${slug}`
                )
            }

            if (planType === 'PASS') {
                return preserveModeLink(`${storefrontPrefix}/passes/${slug}`)
            }

            if (planType === 'PROGRAM') {
                return preserveModeLink(`${storefrontPrefix}/programs/${slug}`)
            }

            if (planType === 'GIFTCARD') {
                return preserveModeLink(
                    `${storefrontPrefix}/gift-cards/${slug}`
                )
            }

            throw new Error()
        },

        storefrontHomeLink(storefrontPrefix: string) {
            return preserveModeLink(`${storefrontPrefix}`)
        },

        storefrontNodeLink(storefrontPrefix: string, slug: string) {
            return preserveModeLink(`${storefrontPrefix}/n/${slug}`)
        },

        storefrontCalendarGroupLink(
            storefrontPrefix: string,
            parentSlug: string,
            groupSlug: string
        ) {
            return preserveModeLink(
                `${storefrontPrefix}/n/${parentSlug}?group=${groupSlug}`
            )
        },

        profileLink() {
            return preserveModeLink(`/profile`)
        },

        profileSettingsLink() {
            return preserveModeLink(`/profile/settings`)
        },

        profileInvoiceLink(id: string) {
            return preserveModeLink(`/profile/invoices/${id}`)
        },

        profileInvoicesLink() {
            return preserveModeLink(`/profile/account/orders`)
        },

        profilePaymentsLink() {
            return preserveModeLink(`/profile/account/payments`)
        },

        profileMembershipLink(id: string) {
            return preserveModeLink(`/profile/memberships/${id}`)
        },

        profileMembershipsLink() {
            return preserveModeLink(`/profile/memberships`)
        },

        profileBookingLink(id: string) {
            return preserveModeLink(`/profile/bookings/${id}`)
        },

        profileBookingsLink() {
            return preserveModeLink(`/profile/bookings`)
        },

        profilePassLink(id: string) {
            return preserveModeLink(`/profile/passes/${id}`)
        },

        profilePassesLink() {
            return preserveModeLink('/profile/passes')
        },

        profileAgreementLink(id: string) {
            return preserveModeLink(`/profile/agreements/${id}`)
        },

        profileAgreementsLink() {
            return preserveModeLink(`/profile/agreements`)
        },

        profilePaymentMethodsLink() {
            return preserveModeLink(`/profile/payment-methods`)
        },

        profileFriendsAndFamilyLink() {
            return preserveModeLink(`/profile/friends-and-family`)
        },

        profileCustomerInfoLink() {
            return preserveModeLink(`/profile/customer-info`)
        },

        profileContactUsLink() {
            return preserveModeLink(`/profile/contact-us`)
        },

        profileCheckInLink() {
            return preserveModeLink(`/profile/check-in`)
        }
    }
}

export function unsafe_facilityCreateContractLink(
    storefrontPrefix: string,
    planType: PlanType,
    slug: string
) {
    if (planType === 'AGREEMENT') {
        return `${storefrontPrefix}/agreements/${slug}`
    }

    if (planType === 'MEMBERSHIP') {
        return `${storefrontPrefix}/memberships/${slug}`
    }

    if (planType === 'PASS') {
        return `${storefrontPrefix}/passes/${slug}`
    }

    if (planType === 'PROGRAM') {
        return `${storefrontPrefix}/programs/${slug}`
    }

    if (planType === 'GIFTCARD') {
        return `${storefrontPrefix}/gift-cards/${slug}`
    }

    throw new Error()
}

export function unsafe_facilityCartLink(facilitySlug: string) {
    return `/${facilitySlug}/cart`
}

export function unsafe_emptyLink() {
    return `/empty`
}

export function unsafe_checkInLink() {
    return `/profile/check-in`
}

export function layoutModeLightboxLink(url: string) {
    return '/lightbox' + url
}

export function layoutModeKioskLink(kioskSlug: string, url: string) {
    return '/kiosk-' + kioskSlug + url
}

import { default as _91slug_93Dxjra70GHuMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/agreements/[slug].vue?macro=true";
import { default as _91id_9342w87mO6UzMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/booking/[id].vue?macro=true";
import { default as indexLdOjwX9No6Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/cart/index.vue?macro=true";
import { default as _91slug_93q5nFtqAy3cMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/embed/[slug].vue?macro=true";
import { default as _91id_93tEpGK4eCxFMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/gift-card/[id].vue?macro=true";
import { default as _91slug_93pZxJyzGj3PMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/gift-cards/[slug].vue?macro=true";
import { default as indexaIXfJWWoD8Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/index.vue?macro=true";
import { default as indexF5OJzx4KtkMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/kaboom/index.vue?macro=true";
import { default as _91id_93cXyqNLlsdLMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/membership/[id].vue?macro=true";
import { default as _91slug_93X8lUWE701OMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/memberships/[slug].vue?macro=true";
import { default as _91slug_93GoVzkyqId1Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/n/[slug].vue?macro=true";
import { default as _91id_93NpaMpK53E7Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/pass/[id].vue?macro=true";
import { default as _91slug_93TV3za9spBcMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/passes/[slug].vue?macro=true";
import { default as _91slug_9341nWRB2dA7Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/programs/[slug].vue?macro=true";
import { default as indexI6lD9nl1y2Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/sign-in/index.vue?macro=true";
import { default as _91id_93EMv6AWrRIoMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/checkout/[id].vue?macro=true";
import { default as empty9VLMcxJ0TIMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/empty.vue?macro=true";
import { default as indexU1XohAcPeeMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/index.vue?macro=true";
import { default as orderskl3FgISVniMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/account/orders.vue?macro=true";
import { default as payments3VkmPPx8kOMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/account/payments.vue?macro=true";
import { default as accountnQn0evgqPcMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/account.vue?macro=true";
import { default as _91id_93P1CtHxhUv1Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/agreements/[id].vue?macro=true";
import { default as indexMSXXFa2MWxMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/agreements/index.vue?macro=true";
import { default as _91id_9338fht2Ma66Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/bookings/[id].vue?macro=true";
import { default as indexERa0OYFa74Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/bookings/index.vue?macro=true";
import { default as check_45in8sBPw6HCXmMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/check-in.vue?macro=true";
import { default as contact_45usYarQtvvRv9Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/contact-us.vue?macro=true";
import { default as customer_45info5bznafsCjHMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/customer-info.vue?macro=true";
import { default as friends_45and_45familyQEmUyaBRaFMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/friends-and-family.vue?macro=true";
import { default as index3Fnd9guXclMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/index.vue?macro=true";
import { default as _91id_93xi7ZxhAXH6Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/invoices/[id].vue?macro=true";
import { default as _91id_93Sukiz7AeK3Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/memberships/[id].vue?macro=true";
import { default as indexh0F85J4QyKMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/memberships/index.vue?macro=true";
import { default as _91id_93oMGb4TiqsQMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/passes/[id].vue?macro=true";
import { default as index8unEIRckG8Meta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/passes/index.vue?macro=true";
import { default as payment_45methodsiKdmqWHtzQMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/payment-methods.vue?macro=true";
import { default as settingsMK9r5KflLXMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/settings.vue?macro=true";
import { default as sign_45inVzV0sDJBWTMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/sign-in.vue?macro=true";
import { default as unsubscribeDghbLDdS4cMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/unsubscribe.vue?macro=true";
import { default as unsubscribed0sS2iPuICAMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/unsubscribed.vue?macro=true";
import { default as cart_45iconPA74re5RbaMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/cart-icon.vue?macro=true";
import { default as page_45errorAyX0130OMRMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/page-error.vue?macro=true";
import { default as page_45not_45foundWxATmOV97xMeta } from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/page-not-found.vue?macro=true";
export default [
  {
    name: "mode-facility-storefront-agreements-slug",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/agreements/:slug()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/agreements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-booking-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/booking/:id()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-cart",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/cart",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-embed-slug",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/embed/:slug()",
    meta: _91slug_93q5nFtqAy3cMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/embed/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-gift-card-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/gift-card/:id()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/gift-card/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-gift-cards-slug",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/gift-cards/:slug()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/gift-cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-kaboom",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/kaboom",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/kaboom/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-membership-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/membership/:id()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/membership/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-memberships-slug",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/memberships/:slug()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/memberships/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-n-slug",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/n/:slug()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/n/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-pass-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/pass/:id()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-passes-slug",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/passes/:slug()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/passes/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-programs-slug",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/programs/:slug()",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/programs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-storefront-sign-in",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/:storefront?/sign-in",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/[[storefront]]/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-facility-checkout-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/:facility((?!kiosk\\)[^/]+)/checkout/:id()",
    meta: _91id_93EMv6AWrRIoMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/[facility]/checkout/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-empty",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/empty",
    meta: empty9VLMcxJ0TIMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/empty.vue").then(m => m.default || m)
  },
  {
    name: "mode",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?",
    meta: indexU1XohAcPeeMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-account",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/account",
    meta: accountnQn0evgqPcMeta || {},
    redirect: "orders",
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/account.vue").then(m => m.default || m),
    children: [
  {
    name: "mode-profile-account-orders",
    path: "orders",
    meta: orderskl3FgISVniMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/account/orders.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-account-payments",
    path: "payments",
    meta: payments3VkmPPx8kOMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/account/payments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mode-profile-agreements-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/agreements/:id()",
    meta: _91id_93P1CtHxhUv1Meta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/agreements/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-agreements",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/agreements",
    meta: indexMSXXFa2MWxMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/agreements/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-bookings-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/bookings/:id()",
    meta: _91id_9338fht2Ma66Meta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-bookings",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/bookings",
    meta: indexERa0OYFa74Meta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-check-in",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/check-in",
    meta: check_45in8sBPw6HCXmMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/check-in.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-contact-us",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/contact-us",
    meta: contact_45usYarQtvvRv9Meta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-customer-info",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/customer-info",
    meta: customer_45info5bznafsCjHMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/customer-info.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-friends-and-family",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/friends-and-family",
    meta: friends_45and_45familyQEmUyaBRaFMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/friends-and-family.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile",
    meta: index3Fnd9guXclMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-invoices-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/invoices/:id()",
    meta: _91id_93xi7ZxhAXH6Meta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-memberships-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/memberships/:id()",
    meta: _91id_93Sukiz7AeK3Meta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/memberships/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-memberships",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/memberships",
    meta: indexh0F85J4QyKMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/memberships/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-passes-id",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/passes/:id()",
    meta: _91id_93oMGb4TiqsQMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/passes/[id].vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-passes",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/passes",
    meta: index8unEIRckG8Meta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/passes/index.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-payment-methods",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/payment-methods",
    meta: payment_45methodsiKdmqWHtzQMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "mode-profile-settings",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/profile/settings",
    meta: settingsMK9r5KflLXMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "mode-sign-in",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/sign-in",
    meta: sign_45inVzV0sDJBWTMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "mode-unsubscribe",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/unsubscribe",
    meta: unsubscribeDghbLDdS4cMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "mode-unsubscribed",
    path: "/:mode(lightbox|kiosk-[A-Za-z0-9\\-]*)?/unsubscribed",
    meta: unsubscribed0sS2iPuICAMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/[[mode]]/unsubscribed.vue").then(m => m.default || m)
  },
  {
    name: "cart-icon",
    path: "/cart-icon",
    meta: cart_45iconPA74re5RbaMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/cart-icon.vue").then(m => m.default || m)
  },
  {
    name: "page-error",
    path: "/page-error",
    meta: page_45errorAyX0130OMRMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/page-error.vue").then(m => m.default || m)
  },
  {
    name: "page-not-found",
    path: "/page-not-found",
    meta: page_45not_45foundWxATmOV97xMeta || {},
    component: () => import("/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/pages/page-not-found.vue").then(m => m.default || m)
  }
]
<template>
    <div class="flex flex-1 flex-col">
        <app-base>
            <header class="border-b">
                <div
                    class="relative flex flex-col justify-center overflow-hidden bg-white"
                >
                    <div>
                        <div
                            class="relative mx-0 flex items-center justify-between gap-4 px-4 py-6 md:px-7 lg:static lg:px-16 lg:py-6"
                        >
                            <div class="flex items-center gap-8">
                                <organization-facility-logo />

                                <a
                                    :href="
                                        facilityHomeLink(activeFacility.slug)
                                    "
                                >
                                    <prime-button
                                        type="button"
                                        label="Home"
                                    />
                                </a>

                                <div class="font-semibold text-gray-600">
                                    {{ activeFacility.shortName }}
                                </div>
                            </div>

                            <div
                                class="flex w-full flex-1 items-center justify-end"
                            >
                                <ul class="flex items-center">
                                    <profile-buttons
                                        v-if="showProfileButtons"
                                    />

                                    <prime-button
                                        v-else-if="viewer !== undefined"
                                        type="button"
                                        label="Sign Out"
                                        :loading="isSigningOut"
                                        @click="signOut"
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div class="flex flex-1 flex-col">
                <div
                    class="z-2 mx-0 my-2 flex-1 px-4 py-4 md:mx-6 md:my-6 lg:mx-8 lg:px-8"
                >
                    <div class="mx-auto flex max-w-screen-xl items-start gap-8">
                        <!-- duplicated div to try and fix some vue bugs -->
                        <div
                            v-if="checkoutResultData !== undefined"
                            class="w-full"
                        >
                            <checkout-success-kiosk
                                :checkout-result-data="checkoutResultData"
                                @continue="handleContinue"
                                @reset="handleReset"
                            />
                        </div>

                        <div
                            v-else
                            class="w-full"
                        >
                            <slot />
                        </div>
                    </div>
                </div>

                <powered-by-footer
                    :kiosk-name="kioskName"
                    @verify="handleVerifyKiosk"
                />
            </div>

            <div class="fixed left-0 right-0 top-10 flex flex-col items-center">
                <prime-message
                    v-if="resetCounter !== null"
                    severity="warn"
                    :pt="{
                        button: {
                            class: 'hidden'
                        }
                    }"
                    @close="handleClose"
                >
                    <div
                        class="flex items-center justify-between gap-16 lg:min-w-[500px]"
                    >
                        <div>{{ resetMessage }}</div>

                        <prime-button
                            label="I'm Still Working!"
                            @click="handleClose"
                        />
                    </div>
                </prime-message>
            </div>

            <prime-dialog
                v-model:visible="showPromptToSignOut"
                modal
                :draggable="false"
                header="Continue"
                :style="{ width: '25rem' }"
                :pt="{
                    rejectButton: {
                        outlined: true
                    }
                }"
                :pt-options="{
                    mergeSections: true,
                    mergeProps: true
                }"
            >
                <div class="flex w-full flex-col items-center gap-3">
                    <i
                        class="pi pi-question-circle text-4xl text-primary-500"
                    ></i>
                    <p>
                        Would you like to continue as
                        <span class="font-semibold">{{ viewer.firstName }}</span
                        >?
                    </p>
                </div>

                <template #footer>
                    <prime-button
                        :label="`Continue as ${viewer.firstName}`"
                        text
                        autofocus
                        class="mr-auto"
                        @click="showPromptToSignOut = false"
                    />

                    <prime-button
                        label="No"
                        outlined
                        autofocus
                        @click="signOut"
                    />
                </template>
            </prime-dialog>

            <prime-dialog
                v-model:visible="showPromptToVerify"
                modal
                :draggable="false"
                header="Verify Kiosk"
                :style="{ width: '25rem' }"
                :pt="{
                    rejectButton: {
                        outlined: true
                    }
                }"
                :pt-options="{
                    mergeSections: true,
                    mergeProps: true
                }"
            >
                <div class="flex w-full flex-col items-center gap-3">
                    <i class="pi pi-check-circle text-4xl text-primary-500"></i>
                    <p>Enter your code from Manage Kiosks</p>
                    <input-text
                        v-model="verifyKioskCode"
                        class="js-VerifyKioskCode w-full !text-[16px]"
                        placeholder="Enter Code"
                        :disabled="isPending"
                        @keydown.enter="
                            () =>
                                verifyKioskCode.length >= 6
                                    ? handleEnterVerifyCode()
                                    : ''
                        "
                    />
                    <span
                        v-if="showVerifyKioskError"
                        class="text-red-600"
                    >
                        Oops! That code didn't seem to work, please try again.
                    </span>
                </div>

                <template #footer>
                    <prime-button
                        label="Cancel"
                        outlined
                        autofocus
                        class="mr-auto"
                        :disabled="isPending"
                        @click="showPromptToVerify = false"
                    />

                    <prime-button
                        label="Verify"
                        autofocus
                        :disabled="isPending || verifyKioskCode.length !== 6"
                        @click="handleEnterVerifyCode"
                    />
                </template>
            </prime-dialog>
        </app-base>
    </div>
</template>

<script setup lang="ts">
import AppBase from '~/components/Layouts/AppBase.vue'
import PoweredByFooter from '~/components/PoweredByFooter.vue'
import OrganizationFacilityLogo from '~/components/Layouts/OrganizationFacilityLogo.vue'
import ProfileButtons from '~/components/Layouts/ProfileButtons.vue'
import CheckoutSuccessKiosk from '~/components/Checkout/CheckoutSuccessKiosk.vue'
import type { StorefrontCatalogScalar } from '~/resources/scalars/storefront-catalog-scalar'
import PrimeMessage from 'primevue/message'
import PrimeButton from 'primevue/button'
import { useIdle, useIntervalFn } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useRouteLink } from '~/composables/routing'
import { useRoute, useState } from '#app'
import {
    useLogoutMutation,
    useSetViewer,
    useViewer
} from '~/composables/use-login'
import PrimeDialog from 'primevue/dialog'
import { useInvalidateCartQuery, useSetCartData } from '~/composables/use-cart'
import { useRpQuery } from '~/composables/graphql'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import {
    type KioskSuccessData,
    provideKioskSuccessFunction
} from '~/composables/use-kiosk-success'
import { useVerifiedKiosk } from '~/composables/use-verified-kiosk'
import InputText from 'primevue/inputtext'

const props = defineProps<{
    catalog: StorefrontCatalogScalar
    storefrontPrefix: string
    profileAllowed: boolean
    idleTimeout: number
    idleTimeoutMessage: string
}>()

const { mutate, isPending: isSigningOut } = useLogoutMutation()

// Subtract 10 for reset counter
const { idle, reset } = useIdle((props.idleTimeout - 10) * 1000)
const resetCounter = ref<number | null>(null)

// Reset idle timer when video ends
const isWatchingWistiaVideo = useState<boolean>(
    'isWatchingWistiaVideo',
    () => false
)
watch(isWatchingWistiaVideo, newValue => {
    if (!newValue) {
        reset()
    }
})

const queryClient = useQueryClient()
const router = useRouter()
const route = useRoute()
const activeFacility = useActiveFacility()
const { facilityHomeLink } = useRouteLink()

const onHomePage = computed(() => {
    return route.path === facilityHomeLink(activeFacility.value.slug)
})

const invalidateCart = useInvalidateCartQuery()
const viewer = useViewer()
const showPromptToSignOut = ref(false)
const preventSignOutPrompt = ref(false)
async function promptToSignOut(newValue: boolean) {
    if (newValue && preventSignOutPrompt.value === false) {
        setTimeout(() => {
            if (
                showPromptToSignOut.value === false &&
                viewer.value !== undefined
            ) {
                showPromptToSignOut.value = true
                // Also clear the cart in the background
                clearCart(undefined, {
                    onSuccess() {
                        invalidateCart()
                        setCartData(undefined)
                    }
                })
            }
        }, 30)
    }
}

watch(onHomePage, promptToSignOut)
onMounted(() => promptToSignOut(onHomePage.value))

watch(idle, newValue => {
    // Idle, not resetting currently and not watching video
    if (
        newValue &&
        resetCounter.value === null &&
        !isWatchingWistiaVideo.value
    ) {
        // Not on the home page or logged in
        if (viewer.value !== undefined) {
            resetCounter.value = 10
        }
    }

    if (!newValue && resetCounter.value !== null) {
        resetCounter.value = null
    }
})

useIntervalFn(() => {
    if (resetCounter.value !== null) {
        resetCounter.value -= 1
        if (resetCounter.value < 1) {
            resetCounter.value = null
            signOut()
        }
    }
}, 1000)

const resetMessage = computed(() => {
    if (props.idleTimeoutMessage !== '') {
        return props.idleTimeoutMessage.replace(
            '{{counter}}',
            String(resetCounter.value)
        )
    } else {
        return `Are you still working? This kiosk will reset in ${resetCounter.value} seconds.`
    }
})

function handleClose() {
    resetCounter.value = null
}

const setViewer = useSetViewer()
const setCartData = useSetCartData()
function signOut() {
    showPromptToSignOut.value = false
    mutate(undefined, {
        onSuccess: data => {
            if (data.logout === true) {
                setViewer(undefined)
                setCartData(undefined)
                queryClient.invalidateQueries()
                router.push(facilityHomeLink(activeFacility.value.slug))
            } else {
                throw new Error()
            }
        },
        onSettled() {
            queryClient.invalidateQueries()
        }
    })
}

const showProfileButtons = computed(() => {
    return props.profileAllowed
})

const query = useRpQuery()
const { mutate: clearCart } = useMutation({
    mutationFn: () =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation ClearCart {
                    clearCart
                }
            `)
        )
})

const checkoutResultData = ref<KioskSuccessData>()
provideKioskSuccessFunction(data => {
    checkoutResultData.value = data
    setViewer(undefined)
    setCartData(undefined)
})

// DEV ONLY: Useful helper to test the checkout result page
// useCheckoutResult((data: FragmentType<typeof CheckoutSuccessFragment>) => {
//     checkoutResultData.value = data
// })

async function handleContinue() {
    preventSignOutPrompt.value = true
    checkoutResultData.value = undefined
    // Just invalidate anyway so it refreshes
    queryClient.invalidateQueries()
    await router.push(facilityHomeLink(activeFacility.value.slug))
    // Use timer to override the watch that would prompt again
    setTimeout(() => {
        preventSignOutPrompt.value = false
    }, 1000)
}

function handleReset() {
    checkoutResultData.value = undefined
    queryClient.invalidateQueries()
    router.push(facilityHomeLink(activeFacility.value.slug))
    signOut()
}

const showPromptToVerify = ref(false)
const verifyKioskCode = ref('')
const showVerifyKioskError = ref(false)
const { kioskName, verifyCode, isPending } = useVerifiedKiosk()
function handleVerifyKiosk() {
    if (kioskName.value !== '') {
        return
    }

    verifyKioskCode.value = ''
    showVerifyKioskError.value = false
    showPromptToVerify.value = true
    setTimeout(() => {
        document.querySelector('.js-VerifyKioskCode')?.focus?.()
    }, 50)
}

watch(verifyKioskCode, () => {
    showVerifyKioskError.value = false
})

function handleEnterVerifyCode() {
    verifyCode(
        verifyKioskCode.value,
        () => {
            showPromptToVerify.value = false
        },
        () => {
            showVerifyKioskError.value = true
            setTimeout(() => {
                document.querySelector('.js-VerifyKioskCode')?.select?.()
            })
        }
    )
}
</script>

<template>
    <div
        v-show="showRecaptcha"
        class="space-y-6"
    >
        <div class="mb-4">
            <span class="text-lg font-medium text-gray-600">{{
                $t('passCaptcha')
            }}</span>
        </div>

        <rp-recaptcha
            v-if="showRecaptcha"
            :lang="recaptchaLang"
            @token="recaptchaToken = $event"
        />

        <prime-button
            :label="$t('continue')"
            class="w-full"
            :disabled="recaptchaDisabled"
            :loading="isVerifyingGuest"
            @click="submitGuest"
        />
    </div>

    <div v-if="showRecaptcha" />

    <div
        v-else-if="token === undefined"
        class="px-3"
    >
        <form @submit="onSubmit">
            <div class="mb-4">
                <span class="text-lg font-medium text-gray-600">{{
                    $t('codePrompt')
                }}</span>
            </div>
            <div class="flex flex-col gap-4">
                <div>
                    <phone-number
                        v-bind="phoneValue"
                        initial-value=""
                        autofocus
                        :disabled="isVerifyingKiosk"
                    />
                    <span class="text-xs"
                        >SMS is only supported for United States and Canadian
                        phone numbers</span
                    >
                    <span
                        v-if="errors.phone"
                        id="email-error"
                        class="text-small text-red-600"
                        >{{ errors.phone }}</span
                    >
                </div>
                <span
                    class="-my-2 w-full text-center font-medium text-gray-500"
                >
                    {{ $t('purchaseContract.or') }}
                </span>
                <div>
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-envelope"></i>
                        <input-text
                            id="email"
                            v-bind="emailValue"
                            inputmode="email"
                            type="email"
                            class="w-full !text-[16px]"
                            :class="{ 'p-invalid': errors.email }"
                            :placeholder="
                                $t('customerInformation.emailAddress')
                            "
                            aria-describedby="email-error"
                            :disabled="isVerifyingKiosk"
                        />
                    </span>
                    <span
                        v-if="errors.email"
                        id="email-error"
                        class="text-small text-red-600"
                        >{{ errors.email }}</span
                    >
                </div>
                <div
                    v-if="submitCount > 0 && errors[''] !== undefined"
                    class="text-red-600 font-medium"
                >
                    {{ errors[''] }}
                </div>
                <div
                    v-if="!isKiosk"
                    class="flex flex-wrap gap-3"
                >
                    <div>
                        <checkbox
                            v-bind="rememberMe"
                            input-id="remember_me"
                            binary
                            class="mr-2"
                            :disabled="isVerifyingKiosk"
                        />
                        <label
                            for="remember_me"
                            class="text-900 mr-8 font-medium"
                        >
                            {{ $t('rememberMe') }}
                        </label>
                    </div>
                </div>

                <div class="flex items-center gap-4">
                    <prime-button
                        v-if="showGuest"
                        outlined
                        :label="$t('continueAsGuest')"
                        class="w-full"
                        :loading="isPending || isVerifyingKiosk"
                        @click="handleRecaptcha"
                    />

                    <prime-button
                        type="submit"
                        :label="$t('signIn')"
                        class="w-full"
                        :loading="isPending || isVerifyingKiosk"
                    />
                </div>
            </div>
        </form>
    </div>

    <choose-customer
        v-else-if="chooseCustomerData !== undefined"
        :choose-customer="chooseCustomerData"
        @done="done"
    />

    <date-of-birth-challenge
        v-else-if="dateOfBirthToken !== undefined"
        :token="dateOfBirthToken"
        :facilities="facilities"
        :org-level="orgLevel"
        @done="done"
        @multiple="chooseCustomerData = $event"
    />

    <pin-verification
        v-else
        v-model:code="twoFactorCode"
        :token="token"
        :phone="phoneValue.modelValue?.formatted"
        :email="emailValue.modelValue"
        @cancel="token = undefined"
        @done="done"
        @collect-name="primaryCustomer = $event"
        @collect-date-of-birth="dateOfBirthToken = $event"
    />
</template>

<script setup lang="ts">
import { boolean, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { useForm } from 'vee-validate'
import PinVerification from '~/components/Verification/PinVerification.vue'
import PrimeButton from 'primevue/button'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { useRpQuery } from '~/composables/graphql'
import { type FragmentType, graphql } from '~/resources/graphql'
import type {
    LoginByEmailInput,
    LoginByPhoneInput,
    ValidateKioskInput,
    VerifyGuestInput
} from '~/resources/graphql/graphql'
import { useToast } from 'primevue/usetoast'
import { useViewer } from '~/composables/use-login'
import PhoneNumber from '~/components/Input/PhoneNumber.vue'
import ChooseCustomer, {
    PossibleCustomerFragment
} from '~/components/Verification/ChooseCustomer.vue'
import DateOfBirthChallenge from '~/components/Verification/DateOfBirthChallenge.vue'
import { useLayoutMode } from '~/composables/use-layout-mode'
import RpRecaptcha from '~/components/Verification/RpRecaptcha.vue'
import { useI18n } from 'vue-i18n'
import { invoke, until } from '@vueuse/core'
import { useVerifiedKiosk } from '~/composables/use-verified-kiosk'
import { useShowAlert } from '#imports'

const props = defineProps<{
    orgLevel?: boolean
}>()

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'guest'): void
}>()

const { t, locale } = useI18n()
const dialogRef = inject('dialogRef', null)
const showGuest = computed(() => dialogRef?.value?.data.showGuest ?? false)
const showRecaptcha = ref(false)
const recaptchaToken = ref('')

const recaptchaLang = computed(() => {
    return locale.value === 'es-ES' ? 'es' : undefined
})

const { isKiosk } = useLayoutMode()
const token = ref<string>()
const dateOfBirthToken = ref<string>()
const primaryCustomer = ref<{ id: string; homeFacilityId: string }>()
const chooseCustomerData = ref<FragmentType<typeof PossibleCustomerFragment>>()

const schema = toTypedSchema(
    object({
        phone: object()
            .shape({
                formatted: string(),
                number: string(),
                valid: boolean()
            })
            .test({
                message: t('customerInformation.invalidMobilePhoneWarning'),
                test: value =>
                    value.number === undefined ||
                    value.number === '' ||
                    value.valid === true
            }),
        email: string()
            .email(t('customerInformation.invalidEmailWarning'))
            .default(''),
        rememberMe: boolean().default(false)
    }).test(
        'emailOrPhone',
        t('codeError'),
        value =>
            value.email.length > 0 ||
            (value.phone?.number !== undefined && value.phone.number.length > 0)
    )
)

const { errors, defineComponentBinds, handleSubmit, submitCount } = useForm({
    validationSchema: schema
})

const phoneValue = defineComponentBinds('phone', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})
const emailValue = defineComponentBinds('email', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})
const rememberMe = defineComponentBinds('rememberMe')

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
function isMobile() {
    let check = false
    ;(function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    return check
}

const query = useRpQuery({ orgLevel: props.orgLevel ?? false })
// TODO: when there are more than 100 facilities
// TODO: Limit to active facilities
const { data } = useQuery({
    queryKey: ['LoginModalQueryQuery'],
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query LoginModalQueryQuery {
                    organization {
                        rememberMeDefault
                    }
                    facilities(first: 100) {
                        edges {
                            node {
                                id
                                shortName
                                active
                                storefront {
                                    id
                                }
                            }
                        }
                    }
                }
            `)
        )
})

onMounted(() => {
    if (isKiosk.value === false && isMobile()) {
        invoke(async () => {
            await until(data).toMatch(v => v !== undefined)
            if (
                data.value !== undefined &&
                data.value.organization.rememberMeDefault
            ) {
                rememberMe.value['onUpdate:modelValue'](true)
            }
        })
    }
})

const facilities = computed(() => {
    return (
        data.value?.facilities.edges
            .filter(edge => {
                return (
                    edge.node.active === true && edge.node.storefront !== null
                )
            })
            .map(edge => ({
                id: edge.node.id,
                shortName: edge.node.shortName
            })) ?? []
    )
})

const { mutate: loginByEmail, isPending: isLoggingInByEmail } = useMutation({
    mutationFn: (input: LoginByEmailInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation LoginByEmail($input: LoginByEmailInput!) {
                    loginByEmail(input: $input) {
                        __typename
                        ... on LoginByEmailResult {
                            token
                        }
                        ... on ValidationError {
                            fieldErrors {
                                message
                            }
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const { mutate: loginByPhone, isPending: isLoggingInByPhone } = useMutation({
    mutationFn: (input: LoginByPhoneInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation LoginByPhone($input: LoginByPhoneInput!) {
                    loginByPhone(input: $input) {
                        __typename
                        ... on LoginByPhoneResult {
                            token
                        }
                        ... on ValidationError {
                            fieldErrors {
                                message
                            }
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const isPending = computed(
    () => isLoggingInByEmail.value || isLoggingInByPhone.value
)

const onSubmit = handleSubmit(values => {
    if (values.phone.valid === true) {
        loginByPhone(
            {
                phone: values.phone.number,
                remember: values.rememberMe
            },
            {
                onSuccess: data => {
                    // If failure, show the errors
                    if (data.loginByPhone.__typename === 'ValidationError') {
                        // TODO: This
                        return
                    }
                    // Otherwise
                    else {
                        token.value = data.loginByPhone.token
                    }
                }
            }
        )
    } else if (values.email !== '') {
        loginByEmail(
            {
                email: values.email,
                remember: values.rememberMe
            },
            {
                onSuccess: data => {
                    // If failure, show the errors
                    if (data.loginByEmail.__typename === 'ValidationError') {
                        // TODO: This
                        return
                    }
                    // Otherwise
                    else {
                        token.value = data.loginByEmail.token
                    }
                }
            }
        )
    } else {
        // TODO: Handle error
        throw new Error()
    }
})

const toast = useToast()
const viewer = useViewer()
function done() {
    emit('close')
    toast.add({
        severity: 'success',
        summary: t('signInComplete'),
        detail: viewer.value?.name ?? '',
        life: 3000
    })
}

// Do this here so that credentials.get is 100% called before the text message is sent
const twoFactorCode = ref('')
let ac = new AbortController()
onMounted(() => {
    if ('OTPCredential' in window) {
        navigator.credentials
            .get({
                otp: { transport: ['sms'] },
                signal: ac.signal
            })
            .then(otp => {
                twoFactorCode.value = otp.code
            })
            .catch(err => {
                if (err !== 'left-page') {
                    console.log(err)
                }
            })
    }
})
onUnmounted(() => {
    if ('OTPCredential' in window) {
        // Just give a reason to prevent warnings
        ac.abort('left-page')
        ac = undefined
    }
})

const { mutate: verifyGuest, isPending: isVerifyingGuest } = useMutation({
    mutationFn: (input: VerifyGuestInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation VerifyGuest($input: VerifyGuestInput!) {
                    verifyGuest(input: $input) {
                        __typename
                        ... on VerifyGuestResult {
                            result
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const recaptchaDisabled = computed(() => {
    return recaptchaToken.value === '' && !import.meta.env.DEV
})

function submitGuest() {
    verifyGuest(
        { token: recaptchaToken.value },
        {
            onSuccess: () => {
                emit('guest')
            }
        }
    )
}

const { mutate: validateVerifiedKiosk, isPending: isVerifyingKiosk } =
    useMutation({
        mutationFn: (input: ValidateKioskInput) =>
            query(
                graphql(/** @lang GraphQL */ `
                    mutation ValidateKiosk($input: ValidateKioskInput!) {
                        validateKiosk(input: $input) {
                            __typename
                            ... on ValidateKioskResult {
                                result
                            }
                        }
                    }
                `),
                {
                    input
                }
            )
    })

const showAlert = useShowAlert()
const { token: verifiedKioskToken, clearToken } = useVerifiedKiosk()
function handleRecaptcha() {
    if (isKiosk.value && verifiedKioskToken.value !== '') {
        validateVerifiedKiosk(
            { token: verifiedKioskToken.value },
            {
                onSuccess: data => {
                    if (
                        data.validateKiosk.__typename === 'ValidateKioskResult'
                    ) {
                        if (data.validateKiosk.result) {
                            emit('guest')
                        } else {
                            clearToken()
                            showRecaptcha.value = true
                        }
                    } else {
                        showAlert(
                            'Heads Up!',
                            'Whoops, something went wrong. Please refresh and try again.'
                        )
                    }
                }
            }
        )
    } else {
        showRecaptcha.value = true
    }
}
</script>

<style>
.p-input-icon-left,
.p-input-icon-right {
    position: relative;
    display: inline-block;
}

.p-input-icon-left > i:first-of-type {
    left: 0.75rem;
    color: #64748b;
}

.p-input-icon-left > input {
    padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
    left: 2.5rem;
}

.p-input-icon-left > i,
.p-input-icon-left > svg,
.p-input-icon-right > i,
.p-input-icon-right > svg {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
}
</style>
